<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'en'"> NEW</span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'id'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modified
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items class="mx-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    icon
                    class="px-5"
                    x-small
                  >
                    <v-icon color="black" v-if="false">mdi-translate</v-icon>
                    <v-avatar size="18" class="elevation-1">
                      <v-img :src="getFlagImage(lang)"></v-img>
                    </v-avatar>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in menu_language"
                    :key="index"
                    dense
                  >
                    <v-list-item-title>
                      <v-avatar size="22" class="elevation-1">
                        <v-img :src="getFlagImage(item.id)"></v-img>
                      </v-avatar>
                      <v-btn
                        small
                        plain
                        elevation="0"
                        @click="languageChange(item.id)"
                      >
                        {{ item.description }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up mr-10"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <v-col cols="12" md="4" sm="6">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 10 : 1"
                      :class="[
                        { 'on-hover': hover },
                        hover ? 'card-hover-opacity' : 'card-not-hover-opacity',
                      ]"
                    >
                      <v-img
                        :lazy-src="lookupImageUrlLazy(itemModified)"
                        :src="lookupImageUrl(itemModified)"
                        height="225px"
                      >
                        <v-card-title class="text-h6 white--text fill-height">
                          <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                          >
                            <v-spacer />
                            <div class="align-self-center">
                              <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                              >
                                <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                                >
                                  mdi-upload
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-row>
                        </v-card-title>
                      </v-img>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="12" md="8" sm="6">
                  <v-row>
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        v-model="itemModified.kode1"
                        label="Kode"
                        :rules="rulesNotEmtpy"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-if="lang === 'id'"
                        v-model="itemModified.description"
                        :rules="rulesNotEmtpy"
                        label="Nama DayaDukung"
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-if="lang === 'en'"
                        v-model="itemModified.descriptionEn"
                        :rules="rulesNotEmtpy"
                        label="Name of Carrying Capacity"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="itemModified.tahun"
                        :label="lang === 'id' ? 'Tahun' : 'Year'"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="itemModified.statusActive"
                        :label="
                          itemModified.statusActive ? 'Aktif' : 'Non-Aktif'
                        "
                        x-small
                        dense
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="itemModified.showToPublic"
                        :label="
                          itemModified.showToPublic
                            ? 'Tampilkan pada Halaman Public'
                            : 'Jangan Tampilkan pada Halaman Public'
                        "
                        x-small
                        dense
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="itemModified.showToMap"
                        :label="
                          itemModified.showToMap
                            ? 'Tampilkan Pada Peta'
                            : 'Jangan Tampilkan Pada Peta'
                        "
                        x-small
                        dense
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fdayaDukungTypeBean"
                    :items="itemsFDayaDukungType"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id'
                        ? 'Jenis Daya Dukung'
                        : 'Carrying Capacity Type'
                    "
                    :hint="
                      lang === 'id'
                        ? `Jenis Daya Dukung : ${
                            lookupFDayaDukungType(
                              itemModified.fdayaDukungTypeBean
                            ).kode1
                          }`
                        : `Jenis Daya Dukung : ${
                            lookupFDayaDukungType(
                              itemModified.fdayaDukungTypeBean
                            ).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="lang === 'id' ? 'Pemilik Data' : 'Data Owner'"
                    :hint="
                      lang === 'id'
                        ? `Pemilik Data : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                        : `Data Owner : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-autocomplete
                    v-model="itemModified.fareaBean"
                    :items="itemsFArea"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="lang === 'id' ? 'Kode Kecamatan' : 'District Code'"
                    :hint="
                      lang === 'id'
                        ? `Kode Kecamatan : ${
                            lookupFArea(itemModified.fareaBean).kode1
                          }`
                        : `District Code : ${
                            lookupFArea(itemModified.fareaBean).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.notes"
                    label="Catatan/Sumber"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.notesEn"
                    label="Notes/Source"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.nilai1"
                    label="NILAI-1"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.nilai2"
                    label="NILAI-2"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.nilai3"
                    label="NILAI-3"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.nilaiInt1"
                    label="Nilai Int-1"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.nilaiInt2"
                    label="Nilai Int-2"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row> </v-row>

              <v-row v-if="itemModified.showToMap === false">
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="itemModified.lat"
                    label="Lattitude"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="itemModified.lon"
                    label="Longitude"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-else class="green lighten-4 rounded">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3" md="3">
                      <v-combobox
                        dense
                        small="true"
                        label="File Type"
                        :items="[
                          'GeoJSON PolyLine',
                          'GeoJSON Line',
                          'GeoJSON Point',
                          'SHP PolyLine',
                          'SHP Line',
                          'SHP Point',
                        ]"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        v-model="itemModified.color1"
                        label="Color1"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        v-model="itemModified.color2"
                        label="Color2"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <div class="mb-n2 text-right caption font-weight-bold">
                        LOW RES
                      </div>
                      <div class="blue lighten-4 rounded elevation-1">
                        <v-btn
                          class="amber darken-1 elevation-6 ma-1"
                          @click="showDialogUploadSpasial('lowres')"
                          icon
                        >
                          <v-icon color="white">mdi-upload</v-icon>
                        </v-btn>
                        <span v-if="itemModified.fileNameLow === ''"
                          >No File (Low Res)</span
                        >
                        <a
                          v-else
                          :href="`${downloadFile(itemModified.fileNameLow)}`"
                          target="_blank"
                        >
                          {{ itemModified.fileNameLow }}
                          <v-icon color="green" x-small>mdi-download</v-icon>
                        </a>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <div class="mb-n2 text-right caption font-weight-bold">
                        HIGH RES
                      </div>
                      <div class="blue lighten-4 rounded elevation-1">
                        <v-btn
                          class="primary elevation-6 ma-1"
                          @click="showDialogUploadSpasial('highres')"
                          icon
                        >
                          <v-icon color="white">mdi-upload</v-icon>
                        </v-btn>
                        <span v-if="itemModified.fileNameHigh === ''"
                          >No File (High Res)</span
                        >
                        <a
                          v-else
                          :href="`${downloadFile(itemModified.fileNameHigh)}`"
                          target="_blank"
                        >
                          {{ itemModified.fileNameHigh }}
                          <v-icon color="green" x-small>mdi-download</v-icon>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="translateForm"
              class="hidden-sm-and-down mr-4"
              :disabled="
                lang !== 'en' &&
                (itemModified.id !== 0 || itemModified.id !== null)
              "
            >
              {{ lang === "id" ? "Terjemahkan" : "Translate" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Batal" : "Cancel" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Simpan" : "Save" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      ></UploadImageDialog>
      <UploadSpasialDialog
        ref="refUploadDialogSpasial"
        @eventUploadSuccess="completeUploadSuccessSpasial"
      ></UploadSpasialDialog>

      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FDayaDukungService from "../../../services/apiservices/f-dayadukung-service";

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FDayaDukung from "../../../models/f-dayadukung";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";
import UploadSpasialDialog from "@/components/utils/UploadSpasialDialog.vue";

export default {
  components: {
    UploadSpasialDialog,
    CloseConfirmDialog,
    UploadImageDialog,
  },
  props: {
    formMode: String,
    itemsFDivision: [],
    itemsFDayaDukungType: [],
    itemsFArea: [],
  },
  data() {
    // const initLang = localStorage.getItem("lang") || "id"
    return {
      // lang: initLang,
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.jpg" },
      ],

      title: "DayaDukung",
      snackBarMesage: "",
      snackbar: false,

      dialogLoading: false,
      loading: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FDayaDukung(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          // this.fetchFSectorType()
        }
      },
    },
  },

  methods: {
    downloadFile(item) {
      // console.log(item);
      return FileService.file_url(item);
    },
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },
    languageChange(value) {
      this.lang = value;
      this.$i18n.locale = this.lang;
      // console.log(value)
      // localStorage.setItem("lang", value)
      /**
       * Perintah untuk Change Language
       */
      // this.$i18n.locale = localStorage.getItem("lang");
      // this.lang = localStorage.getItem("lang")
    },
    translateForm() {
      this.snackBarMesage = "";
      this.dialogLoading = true;
      this.loading = true;
      let itemFDayaDukungTranslate = [];
      let emptyValue = "-";
      if (
        this.itemModified.description !== null &&
        this.itemModified.description !== undefined &&
        this.itemModified.description !== "" &&
        this.itemModified.descriptionEn !== "-" &&
        (this.itemModified.descriptionEn === null ||
          this.itemModified.descriptionEn === undefined ||
          this.itemModified.descriptionEn === "" ||
          this.itemModified.descriptionEn === "-")
      ) {
        itemFDayaDukungTranslate.push(this.itemModified.description);
      } else {
        itemFDayaDukungTranslate.push(emptyValue);
      }
      if (
        this.itemModified.notes !== null &&
        this.itemModified.notes !== undefined &&
        this.itemModified.notes !== "" &&
        this.itemModified.notes !== "-" &&
        (this.itemModified.notesEn === null ||
          this.itemModified.notesEn === undefined ||
          this.itemModified.notesEn === "" ||
          this.itemModified.notesEn === "-")
      ) {
        itemFDayaDukungTranslate.push(this.itemModified.notes);
      } else {
        itemFDayaDukungTranslate.push(emptyValue);
      }
      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(
        encodeURIComponent(JSON.stringify(itemFDayaDukungTranslate))
      ).then(
        (response) => {
          try {
            let itemFDayaDukungTranslateResponse = decodeURIComponent(
              response.data.translation
            )
              .replaceAll("+", " ")
              .replaceAll("=", " ")
              .trim();
            let itemFDayaDukungTranslateResponseParsed = JSON.parse(
              itemFDayaDukungTranslateResponse
            );
            if (
              (this.itemModified.descriptionEn === null ||
                this.itemModified.descriptionEn === undefined ||
                this.itemModified.descriptionEn === "" ||
                this.itemModified.descriptionEn === "-") &&
              itemFDayaDukungTranslateResponseParsed[0] !== "-"
            ) {
              this.itemModified.descriptionEn =
                itemFDayaDukungTranslateResponseParsed[0];
            }
            if (
              (this.itemModified.notesEn === null ||
                this.itemModified.notesEn === undefined ||
                this.itemModified.notesEn === "" ||
                this.itemModified.notesEn === "-") &&
              itemFDayaDukungTranslateResponseParsed[1] !== "-"
            ) {
              this.itemModified.notesEn =
                itemFDayaDukungTranslateResponseParsed[1];
            }
            FDayaDukungService.updateFDayaDukung(this.itemModified).then(() => {
              console.log("Update Oke");
            });
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data berhasil diterjemahkan";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "Data successfully translated";
            }
          } catch (error) {
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data yang diterjemahkan melebihi batas";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "The translated data exceeds the limit";
            }
          }
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
          console.log(error.data);
        }
      );
    },

    completeUploadSuccessSpasial: function (val) {
      if (val.senderMessage === "lowres") {
        if (
          this.itemModified.fileNameLow !== undefined &&
          this.itemModified.fileNameLow !== ""
        ) {
          FileService.deleteImage(this.itemModified.fileNameLow).then(
            () => {
              console.log(`Delete Image ${this.itemModified.fileNameLow}`);
            },
            (error) => {
              console.log(error.response);
            }
          );
        }

        if (val.fileName !== "") {
          this.$refs.refUploadDialogSpasial.closeDialog();
          this.itemModified.fileNameLow = val.fileName;
          this.saveUpdateOnly();
        }
      } else if (val.senderMessage === "highres") {
        if (
          this.itemModified.fileNameHigh !== undefined &&
          this.itemModified.fileNameHigh !== ""
        ) {
          FileService.deleteImage(this.itemModified.fileNameHigh).then(
            () => {
              console.log(`Delete Image ${this.itemModified.fileNameHigh}`);
            },
            (error) => {
              console.log(error.response);
            }
          );
        }

        if (val.fileName !== "") {
          this.$refs.refUploadDialogSpasial.closeDialog();
          this.itemModified.fileNameHigh = val.fileName;
          this.saveUpdateOnly();
        }
      }
    },

    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fdivisionBean !== 0 &&
        this.itemModified.fdayaDukungTypeBean !== 0 &&
        this.itemModified.fareaBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi, Jenis dan Kecamatan harus diisi dahulu";
        this.snackbar = true;
      }
    },
    showDialogUploadSpasial(senderMessage) {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fdivisionBean !== 0 &&
        this.itemModified.fdayaDukungTypeBean !== 0 &&
        this.itemModified.fareaBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialogSpasial.showDialog(senderMessage, true);
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi, Jenis dan Kecamatan harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          () => {
            console.log(`Delete Image ${this.itemModified.avatarImage}`);
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item) {
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FDayaDukung(0, "", "")),
          (this.itemModified = new FDayaDukung(0, "", "")),
          (this.selectedIndex = -1);
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FDayaDukungService.updateFDayaDukung(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FDayaDukungService.createFDayaDukung(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FDayaDukungService.createFDayaDukung(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          // this.initializeEditMode(response.data)
          this.$emit("update:formMode", FormMode.EDIT_FORM);
          this.itemModified.id = response.data.id;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FDayaDukungService.updateFDayaDukung(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.formDialogOptions.errorMessage = "";

      FDayaDukungService.getFDayaDukungById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFDayaDukungType(fdayaDukungTypeBean) {
      const str = this.itemsFDayaDukungType.filter(
        (x) => x.id === fdayaDukungTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFArea(fareaBean) {
      const str = this.itemsFArea.filter((x) => x.id === fareaBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
};
</script>

<style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>