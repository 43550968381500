<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
          v-on:keyup.enter="searchOnEnter"
          v-on:blur="searchOnEnter"
          append-icon="mdi-magnify"
          hint="Kode, Deskripsi (press ENTER to search)"
          label="Search"
      ></v-text-field>
      <v-btn
          @click="showFilterDialog"
          icon fab
          color="blue"
          small
          class="ml-2"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <v-card class="ml-2 mr-2 elevation-0 grey lighten-4 " v-show="showFilter===true">
        <v-card-text>

          <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFdivisions"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  dense
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :label="lang==='id'? 'Divisi':'Division'"
                  multiple
                  hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFdayaDukungType"
                  :items="itemsFDayaDukungType"
                  item-value="id"
                  :item-text="lang==='id'? 'description' : 'descriptionEn'"
                  dense
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :label="lang==='id'? 'Tipe Daya Dukung' : 'Supporting Capacity Type'"
                  multiple
                  hide-details
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFareaBean"
                  :items="itemsFArea"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  :label="lang==='id'? 'Kabupaten-Kota':'District/City'"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="6" sm="2" md="2">
              <v-btn color="primary" small @click="runExtendedFilter">
                {{lang=== 'id'? 'Terapkan':'Apply'}}
                <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fDayaDukungsFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="importDialogShow"
                >
                  <v-icon color="green">mdi-upload</v-icon>
                  Import from Template
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="importExtDialogShow"
                >
                  <v-icon color="red">mdi-upload</v-icon>
                  Import from Template Extended
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                    class="mx-1"
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="translateDialogShow"
                >
                  <v-icon color="red">mdi-translate</v-icon>
                  Translate to English
                </v-btn>
              </v-list-item>
            </v-list>

          </v-menu>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
              class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1" v-if="lang==='id'">
          {{item.description}}
        </div>
        <div class="subtitle-1" v-if="lang==='en'">
          {{item.descriptionEn}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{item.kode1}}
        </div>
      </template>

      <template v-slot:[`item.fdayaDukungTypeBean`]="{ item }">
        <div class="fill-height  mt-1">
          <div  class="text-caption small" v-if="lang==='id'">
            {{ lookupFInfratructureType(item.fdayaDukungTypeBean).description }}
          </div>
          <div  class="text-caption small" v-if="lang==='en'">
            {{ lookupFInfratructureType(item.fdayaDukungTypeBean).descriptionEn }}
          </div>
          <div>
            {{item.tahun}}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fareaBean`]="{ item }">
        <div class="fill-height  mt-1">
          <div  class="text-caption small" v-if="lang==='id'">
            {{ lookupFArea(item.fareaBean).description }}
          </div>
          <div  class="text-caption small" v-if="lang==='en'">
            {{ lookupFArea(item.fareaBean).descriptionEn }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean).description }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive==true?'Aktif': '-' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
<!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>
    <ConfirmDialog
        ref="refConfirmDialog"
        @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>
    <FDayaDukungDialog
        :formMode.sync="formMode"
        :itemsFDivision = "itemsFDivision"
        :itemsFDayaDukungType = "itemsFDayaDukungType"
        :itemsFArea="itemsFArea"
          ref="refFormDialog"
          @eventFromFormDialogNew="saveDataNew"
          @eventFromFormDialogEdit="saveDataEdit"
    ></FDayaDukungDialog>

    <FDayaDukungImportDialog
        :itemsFDivision = "itemsFDivision"
        :itemsFDayaDukungType="itemsFDayaDukungType"
        :itemsFArea="itemsFArea"
          ref="refImportDialog"
          @importExcelCompleted="importExcelCompleted"
    ></FDayaDukungImportDialog>
    <FDayaDukungImportExtDialog
        :itemsFDivision = "itemsFDivision"
        :itemsFDayaDukungType="itemsFDayaDukungType"
        :itemsFArea="itemsFArea"
        ref="refImportExtDialog"
        @importExcelCompleted="importExcelCompleted"
    ></FDayaDukungImportExtDialog>

    <v-dialog width="100px" v-model="dialogLoading" >
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FDayaDukungService from '../../../services/apiservices/f-dayadukung-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FDayaDukungTypeService from "../../../services/apiservices/f-dayadukung-type-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";
import FDayaDukungImportDialog from "@/components/admin_dayadukung/dayadukung/FDayaDukungImportDialog";

import FDayaDukungDialog from "./FDayaDukungDialog";
import FormMode from "../../../models/form-mode";
import FDayaDukung from '../../../models/f-dayadukung'
import FDayaDukungType from '../../../models/f-dayadukung-type'
import FileService from "../../../services/apiservices/file-service";
import FDayaDukungFilter from "@/models/payload/f-dayadukung-filter";
import FAreaService from "@/services/apiservices/f-area-service";
import FDayaDukungImportExtDialog from "@/components/admin_dayadukung/dayadukung/FDayaDukungImportExtDialog";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";


export default {
  components: {
    FDayaDukungImportExtDialog,
    FDayaDukungDialog, DeleteConfirmDialog, FDayaDukungImportDialog, ConfirmDialog},
  data () {
    return {
      title: 'DAYA DUKUNG',
      snackbar: false,
      snackBarMesage: '',

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1500],

      showFilter: false,
      filterFdivisions: [],
      filterFdayaDukungType: [],
      filterFareaBean: [],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Deskripsi', value: 'description', width:"30%" },
        { text: 'Jenis', value: 'fdayaDukungTypeBean' },
        { text: 'Kab/Kota', value: 'fareaBean' },
        { text: 'Division', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fDayaDukungs:[
        new FDayaDukung(0, '','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFDayaDukungType:[
          new FDayaDukungType()
      ],
      itemsFArea: []

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFDayaDukung()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFDayaDukung()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fDayaDukungsFiltered(){
      return this.fDayaDukungs
    }
  },
  methods: {

    showFilterDialog(){
      this.showFilter = !this.showFilter

    },

    runExtendedFilter(){

      const extendedFilter = new FDayaDukungFilter()

      if (this.filterFdivisions.length >0){
        extendedFilter.fdivisionIds = this.filterFdivisions
      }else {
        extendedFilter.fdivisionIds = this.filterFdivisions.map( item => item.id)
      }

      if (this.filterFdayaDukungType.length >0){
        extendedFilter.fdayaDukungTypeIds = this.filterFdayaDukungType
      }else {
        extendedFilter.fdayaDukungTypeIds = this.itemsFDayaDukungType.map( item => item.id)
      }
      extendedFilter.fareaIds = this.filterFareaBean
      if (extendedFilter.fareaIds.length ==0) extendedFilter.fareaIds = []
      // console.log(`>> ${JSON.stringify(extendedFilter.fdayaDukungTypeIds)}`)

      extendedFilter.pageNo = this.currentPage
      extendedFilter.pageSize = this.pageSize
      extendedFilter.sortBy = "id"
      extendedFilter.order = "DESC"
      extendedFilter.search = this.search

      extendedFilter.city = ""

      FDayaDukungService.getPostAllFDayaDukungContainingExt(extendedFilter).then(
          response =>{
            const { items, totalPages} = response.data
            this.fDayaDukungs = items
            this.totalPaginationPages = totalPages

          },
          error =>{
            console.log(error)
          }
      )
    },
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFDayaDukung()
      }
    },

    importDialogShow(){
      this.$refs.refImportDialog.showDialog()
      // console.log("hello bos")
    },
    importExtDialogShow(){
      this.$refs.refImportExtDialog.showDialog()
      // console.log("hello bos")
    },

    importExcelCompleted(value){
      if (value ==='OKE'){
        this.fetchFDayaDukung()
      }
    },

    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )

      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      FDayaDukungTypeService.getAllFDayaDukungType().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFDayaDukungType = response.data
          },
          error => {
            console.log(error.response)
          }
      )
      FAreaService.getAllFArea().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFArea = response.data
          },
          error => {
            console.log(error.response)
          }
      )


    },
    fetchFDayaDukung() {

      if (this.showFilter) {
        this.runExtendedFilter()
      }else {
        FDayaDukungService.getAllFDayaDukungContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
            response => {
              console.log(response.data.items)
              const { items, totalPages} = response.data
              this.fDayaDukungs = items
              this.totalPaginationPages = totalPages

              // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    translateDialogShow(){
      this.$refs.refConfirmDialog.showDialog("Yakin akan Membuat Terjemahan  Data Dalam Daftar? ", "(Yang belum mempunyai terjemahan)")
    },
    //DUAL BAHASA
    confirmDialogOke(){
      this.snackBarMesage = ''
      this.dialogLoading = true
      this.loading = true
      let itemsFDayaDukungTranslate = []
      let itemsFDayaDukungSorted = []
      this.fDayaDukungs.sort((a, b) => a.id > b.id ? 1 : -1).forEach((item) => {
        let itemFDayaDukungTranslate = []
        let emptyValue = "-";
        if ((item.description !== null && item.description !== undefined && item.description !== "" &&item.description !== "-") && (item.descriptionEn === null || item.descriptionEn === undefined ||item.descriptionEn === "" || item.descriptionEn === "-")) {
          itemFDayaDukungTranslate.push(item.description)
        } else {
          itemFDayaDukungTranslate.push(emptyValue);
        }
        if ((item.notes !== null && item.notes !== undefined && item.notes !== "" &&item.notesEn !== "-") && (item.notes === null || item.notesEn === undefined ||item.notesEn === "" || item.notesEn === "-")) {
          itemFDayaDukungTranslate.push(item.notes)
        } else {
          itemFDayaDukungTranslate.push(emptyValue);
        }
        itemsFDayaDukungTranslate.push(itemFDayaDukungTranslate)
        itemsFDayaDukungSorted.push(item)
      })
      console.log(itemsFDayaDukungTranslate)
      // console.log(JSON.stringify(itemsFDayaDukungSorted))
      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(encodeURIComponent(JSON.stringify(itemsFDayaDukungTranslate))).then(
        (response) => {
          try{
            let itemsFDayaDukungTranslateResponse = decodeURIComponent(response.data.translation).replaceAll("+", " ").replaceAll("=", " ").trim()
            let itemsFDayaDukungTranslateResponseParsed = JSON.parse(itemsFDayaDukungTranslateResponse)
            itemsFDayaDukungSorted.forEach((itemFDayaDukungSorted,index)=> {
              if ((itemFDayaDukungSorted.descriptionEn === null ||
              itemFDayaDukungSorted.descriptionEn === undefined ||
              itemFDayaDukungSorted.descriptionEn === "" ||
              itemFDayaDukungSorted.descriptionEn === "-") && itemsFDayaDukungTranslateResponseParsed[index][0] !== "-") {
                itemFDayaDukungSorted.descriptionEn =
                itemsFDayaDukungTranslateResponseParsed[index][0]
              }
              if ((itemFDayaDukungSorted.notesEn === null ||
              itemFDayaDukungSorted.notesEn === undefined ||
              itemFDayaDukungSorted.notesEn === "" ||
              itemFDayaDukungSorted.notesEn === "-") && itemsFDayaDukungTranslateResponseParsed[index][1] !== "-") {
                itemFDayaDukungSorted.notesEn = itemsFDayaDukungTranslateResponseParsed[index][1];
              }
              console.log(itemFDayaDukungSorted)
              FDayaDukungService.updateFDayaDukung(itemFDayaDukungSorted).then(
                ()=>{
                  console.log('Update Oke')
                }
              )
            })
            this.dialogLoading = false;
            this.loading = false
            this.snackbar = true
            if(this.lang==='id'){
              this.snackBarMesage = 'Data berhasil diterjemahkan'
            }
            if(this.lang==='en'){
              this.snackBarMesage = 'Data successfully translated'
            }
            this.$refs.refConfirmDialog.setDialogState(false)
          }
          catch(error){
            this.dialogLoading = false;
            this.loading = false
            this.snackbar = true
            if(this.lang==='id'){
              this.snackBarMesage = 'Data yang diterjemahkan melebihi batas'
            }
            if(this.lang==='en'){
              this.snackBarMesage = 'The translated data exceeds the limit'
            }
            this.$refs.refConfirmDialog.setDialogState(false)
            console.log(error)
          }
          
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
          console.log(error.data);
        }
      );
    },
    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fDayaDukungs.push(this.itemSelected)
      // this.fDayaDukungs = [itemFromRest].concat(this.fDayaDukungs)
      // this.fDayaDukungs.unshift(itemFromRest)
      this.fetchFDayaDukung()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fDayaDukungsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        if (item.lang !== 'id'){
          this.fetchFDayaDukung()
        }else {
          try {
            Object.assign(this.fDayaDukungs[this.itemSelectedIndex], this.itemSelected)
          }catch (e) {
              this.fetchFDayaDukung()
              e.toString()
          }
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fDayaDukungsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fDayaDukungs[this.itemSelectedIndex]
      FDayaDukungService.deleteFDayaDukung(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fDayaDukungs.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)

      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }

        // console.log("Item idex: " + itemIds)

        FDayaDukungService.deleteAllFDayaDukung(itemIds).then(
            response => {
              console.log(response.data)

              if (this.showFilter){
                this.runExtendedFilter()
              }else {
                this.fetchFDayaDukung()
              }
              this.selectedItems = []
              this.closeDialog()
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FDayaDukungService.deleteAllFDayaDukungs()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FDayaDukung())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfratructureType (finfratructureTypeBean) {
      const str = this.itemsFDayaDukungType.filter(x => x.id===finfratructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFDayaDukung()

    }
  }

}
</script>

<style scoped>

</style>