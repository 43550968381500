import { render, staticRenderFns } from "./FDayaDukungTable.vue?vue&type=template&id=e4d6e094&scoped=true"
import script from "./FDayaDukungTable.vue?vue&type=script&lang=js"
export * from "./FDayaDukungTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4d6e094",
  null
  
)

export default component.exports